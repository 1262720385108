<template>
  <div>
    <ms-select
      v-if="form.type"
      v-model="form.account"
      label="Account"
      :options="accountOptions"
      filterable />

    <template v-if="form.account">
      <el-button
        v-if="!data"
        class="w-full mt-20"
        type="success"
        :loading="loading"
        round
        @click="submit()">
        Submit
      </el-button>

      <div
        v-if="loading"
        v-loading="loading"
        class="page-loader" />

      <div v-else-if="data" class="overflow-auto">
        <div class="data-list-item">
          <label class="subtitle">Deposit Details - {{this.selectedAccount.label}}  Account</label>
        </div>
        <el-collapse>
          <el-collapse-item v-if="achDataList.length > 0" title="ACH">
            <data-list
              :list="achDataList"
              class="mt-20" />
          </el-collapse-item>
          <el-collapse-item v-if="domesticWireDataList.length > 0" title="Domestic Wire">
            <data-list
              :list="domesticWireDataList"
              class="mt-20" />
          </el-collapse-item>
          <el-collapse-item v-if="internationalWireDataList.length > 0" title="International Wire">
            <data-list
              :list="internationalWireDataList"
              class="mt-20" />
          </el-collapse-item>
        </el-collapse>
        <data-list
          :list="[noteDepositInstructions]"
          class="mt-20" />
      </div>

      <div
        v-if="data"
        class="mt-20">
        <el-button round @click="clear()">
          Clear
        </el-button>
        <a
          v-if="viewLink"
          :href="viewLink"
          target="_blank">
          <el-button
            type="primary"
            class="ml-10"
            round>
            PDF
          </el-button>
        </a>
      </div>
    </template>

  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  data () {
    return {
      form: {
        type: 'Funds',
        account: null,
        currency: 'USD',
        deposit_type: 'domestic_wire'
      },

      data: null,

      loading: false
    }
  },

  computed: {
    user () {
      return this.$store.state.app.user
    },

    registration () {
      return this.user?.registration || null
    },

    accounts () {
      return this.registration?.accounts || []
    },

    accountOptions () {
      return this.accounts.map(account => ({
        id: account.id,
        regId: this.registration.id,
        status: account.accountStatus,
        text: `${account.accountNumber}-${this.registration.name}`,
        accountNumber: account.accountNumber,
        provider: account.provider,
        profileSettings: account.profileSettings,
        fundsCurrencies: account.fundsCurrencies,
        assetsWallets: account.assetsWallets,
        name: account.accountName,
        reg_name: this.registration.name,
        systemAvailableBalance: account.systemAvailableBalance,
        primeAccount: account.primeAccount,
        label: account.label
      })).filter(account => account.status === 'Open' && (this.isFunds || account.profileSettings.assets))
    },

    viewLink () {
      if (!this.data) return

      const filePath = `${this.$server}files/paymentDetails/${this.form.account}/${this.form.account}`
      var token = localStorage.getItem('token')

      return `${filePath}-payment_details.pdf?token=${token}`
    },

    defaultDataList () {
      return [
        {
          label: 'Bank Name',
          text: this.data['depository-bank-name']
        },
        {
          label: 'Bank Routing Number',
          text: this.data['routing-number']
        },
        {
          label: 'Bank SWIFT Code',
          text: this.data['swift-code']
        },
        {
          label: 'Credit To',
          text: this.data['credit-to']
        },
        {
          label: 'Address',
          text: this.data['beneficiary-address']
        },
        {
          label: 'Account Number',
          text: this.data['account-number']
        },
        {
          label: 'Reference Code',
          text: `${this.data.reference}, ${this.selectedAccount.reg_name}`
        },
        {
          label: 'Bank Address',
          text: this.data['bank-address']
        },
        {
          label: 'Bank Phone',
          text: this.data['bank-phone']
        },
        {
          label: 'Note',
          html: 'Your Q-Code and account name <b>MUST</b> be included on all deposit transactions. ACH deposits are not accepted. Your deposit instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative or contact support. Please follow any specific funding instructions to ensure the funds are received quickly.'
        }
      ]
    },

    accountNumbersList () {
      return [
        {
          label: 'Account Number',
          text: this.data.accountNumber
        },
        {
          label: 'Routing Number',
          text: this.data.routingNumber
        }
      ]
    },

    accountDataList () {
      return [
        {
          label: 'Account Name',
          text: this.data.accountName
        },
        ...this.accountNumbersList,
        {
          label: 'Account Type',
          text: this.data.accountType
        }
      ]
    },

    accountAddressList () {
      return [
        {
          label: 'Account Address',
          text: `${this.data.address['street-1']}, ${this.data.address['street-2']}`
        },
        {
          text: `${this.data.address.city}, ${this.data.address.region}, ${this.data.address['postal-code']}`
        },
        {
          text: this.data.address.country
        }
      ]
    },

    bankName () {
      return {
        label: 'Bank Name',
        text: this.data.bankName
      }
    },

    bankAddress () {
      return {
        label: 'Bank Address',
        text: this.data.bankAddress
      }
    },

    bankDataList () {
      return [this.bankName, this.bankAddress]
    },

    noteDepositInstructions () {
      return {
        label: 'Note',
        text: 'Your account number MUST be included in the reference field on all deposit transactions. Your deposit instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative or contact support. Please follow any specific funding instructions to ensure the funds are received quickly.'
      }
    },

    dataList () {
      const data = [
        {
          subtitle: `Deposit Details - ${this.selectedAccount.label} Account`
        }
      ]

      if (this.isFunds) {
        if (!this.data) return data

        if (this.data.provider === 'Argense') {
          data.push(
            {
              subtitle: 'ACH'
            },
            {
              subtitle: 'Bank Information'
            },
            {
              label: 'Name',
              text: `${this.data.bankName}`
            },
            {
              label: 'Address',
              text: `${this.data.bankAddress}`
            },
            {
              label: 'Phone',
              text: ''
            },
            {
              label: 'Routing Number',
              text: `${this.data.routingNumber}`
            },
            {
              subtitle: 'Beneficiary Information'
            },
            {
              label: 'Credit To',
              text: `${this.data.creditTo}`
            },
            {
              label: 'Address',
              text: `${this.data.address}`
            },
            {
              label: 'Account Number',
              text: `${this.data.accountNumber}`
            },
            {
              label: 'Reference',
              text: `${this.data.reference1}`
            },
            {
              label: 'Reference 2',
              text: `${this.data.reference}`
            }
          )

          data.push(
            {
              subtitle: 'Domestic Wire'
            },
            {
              subtitle: 'Bank Information'
            },
            {
              label: 'Name',
              text: `${this.data.bankName}`
            },
            {
              label: 'Address',
              text: `${this.data.bankAddress}`
            },
            {
              label: 'Phone',
              text: ''
            },
            {
              label: 'Routing Number',
              text: `${this.data.routingNumber}`
            },
            {
              subtitle: 'Beneficiary Information'
            },
            {
              label: 'Credit To',
              text: `${this.data.creditTo}`
            },
            {
              label: 'Address',
              text: `${this.data.address}`
            },
            {
              label: 'Account Number',
              text: `${this.data.accountNumber}`
            },
            {
              label: 'Reference',
              text: `${this.data.reference1}`
            },
            {
              label: 'Reference 2',
              text: `${this.data.reference}`
            }
          )

          data.push(
            {
              subtitle: 'International Wire'
            },
            {
              subtitle: 'Bank Information'
            },
            {
              label: 'Name',
              text: `${this.data.bankName}`
            },
            {
              label: 'Address',
              text: `${this.data.bankAddress}`
            },
            {
              label: 'Phone',
              text: ''
            },
            {
              label: 'SWIFT Code',
              text: `${this.data.swiftCode}`
            },
            {
              subtitle: 'Beneficiary Information'
            },
            {
              label: 'Credit To',
              text: `${this.data.creditTo}`
            },
            {
              label: 'Address',
              text: `${this.data.address}`
            },
            {
              label: 'Account Number',
              text: `${this.data.accountNumber}`
            },
            {
              label: 'Reference',
              text: `${this.data.reference1}`
            },
            {
              label: 'Reference 2',
              text: `${this.data.reference}`
            }
          )
          data.push(this.noteDepositInstructions)
        } else if (this.data.provider === 'Think Green') {
          data.push(
            {
              subtitle: 'Domestic Wire'
            },
            {
              subtitle: 'Bank Information'
            },
            {
              label: 'Name',
              text: `${this.data.bankName}`
            },
            {
              label: 'Address',
              text: `${this.data.bankAddress}`
            },
            {
              label: 'Phone',
              text: ''
            },
            {
              label: 'Routing Number',
              text: `${this.data.routingNumber}`
            },
            {
              subtitle: 'Beneficiary Information'
            },
            {
              label: 'Credit To',
              text: `${this.data.creditTo}`
            },
            {
              label: 'Address',
              text: `${this.data.address}`
            },
            {
              label: 'Account Number',
              text: `${this.data.accountNumber}`
            },
            {
              label: 'Reference',
              text: `${this.data.reference}`
            }
          )

          data.push(
            {
              subtitle: 'International Wire'
            },
            {
              subtitle: 'Bank Information'
            },
            {
              label: 'Name',
              text: `${this.data.bankName}`
            },
            {
              label: 'Address',
              text: `${this.data.bankAddress}`
            },
            {
              label: 'Phone',
              text: ''
            },
            {
              label: 'SWIFT Code',
              text: `${this.data.swiftCode}`
            },
            {
              subtitle: 'Beneficiary Information'
            },
            {
              label: 'Credit To',
              text: `${this.data.creditTo}`
            },
            {
              label: 'Address',
              text: `${this.data.address}`
            },
            {
              label: 'Account Number',
              text: `${this.data.accountNumber}`
            },
            {
              label: 'Reference',
              text: `${this.data.reference}`
            }
          )
          data.push(this.noteDepositInstructions)
        } else {
          if (this.form.deposit_type === 'ach') {
            data.push({
              subtitle: 'ACH deposit details are unavailable at this time.'
            })
          } else {
            data.push(...this.defaultDataList.filter(item => this.form.deposit_type === 'domestic_wire' ? item.label !== 'Bank SWIFT Code' : true))
          }
        }
      }
      return data
    },

    achDataList () {
      const data = [
       
      ]

      if (!this.data) return data

      if (this.data.provider === 'Argense') {
        data.push(
          {
            subtitle: 'Bank Information'
          },
          {
            label: 'Name',
            text: `${this.data.bankName}`
          },
          {
            label: 'Address',
            text: `${this.data.bankAddress}`
          },
          {
            label: 'Phone',
            text: ''
          },
          {
            label: 'Routing Number',
            text: `${this.data.routingNumber}`
          },
          {
            subtitle: 'Beneficiary Information'
          },
          {
            label: 'Credit To',
            text: `${this.data.creditTo}`
          },
          {
            label: 'Address',
            text: `${this.data.address}`
          },
          {
            label: 'Account Number',
            text: `${this.data.accountNumber}`
          },
          {
            label: 'Reference',
            text: `${this.data.reference1}`
          },
          {
            label: 'Reference 2',
            text: `${this.data.reference}`
          }
        )
      }
      return data
    },

    domesticWireDataList () {
      const data = [
       
      ]

      if (!this.data) return data

      if (this.data.provider === 'Argense') {
        data.push(
          {
            subtitle: 'Bank Information'
          },
          {
            label: 'Name',
            text: `${this.data.bankName}`
          },
          {
            label: 'Address',
            text: `${this.data.bankAddress}`
          },
          {
            label: 'Phone',
            text: ''
          },
          {
            label: 'Routing Number',
            text: `${this.data.routingNumber}`
          },
          {
            subtitle: 'Beneficiary Information'
          },
          {
            label: 'Credit To',
            text: `${this.data.creditTo}`
          },
          {
            label: 'Address',
            text: `${this.data.address}`
          },
          {
            label: 'Account Number',
            text: `${this.data.accountNumber}`
          },
          {
            label: 'Reference',
            text: `${this.data.reference1}`
          },
          {
            label: 'Reference 2',
            text: `${this.data.reference}`
          }
        )
      } else if (this.data.provider === 'Think Green') {
        data.push(
          {
            subtitle: 'Bank Information'
          },
          {
            label: 'Name',
            text: `${this.data.bankName}`
          },
          {
            label: 'Address',
            text: `${this.data.bankAddress}`
          },
          {
            label: 'Phone',
            text: ''
          },
          {
            label: 'Routing Number',
            text: `${this.data.routingNumber}`
          },
          {
            subtitle: 'Beneficiary Information'
          },
          {
            label: 'Credit To',
            text: `${this.data.creditTo}`
          },
          {
            label: 'Address',
            text: `${this.data.address}`
          },
          {
            label: 'Account Number',
            text: `${this.data.accountNumber}`
          },
          {
            label: 'Reference',
            text: `${this.data.reference}`
          }
        )
      }
      return data
    },

    internationalWireDataList () {
      const data = [
       
      ]

      if (!this.data) return data

      if (this.data.provider === 'Argense') {
        data.push(
          {
            subtitle: 'Bank Information'
          },
          {
            label: 'Name',
            text: `${this.data.bankName}`
          },
          {
            label: 'Address',
            text: `${this.data.bankAddress}`
          },
          {
            label: 'Phone',
            text: ''
          },
          {
            label: 'SWIFT Code',
            text: `${this.data.swiftCode}`
          },
          {
            subtitle: 'Beneficiary Information'
          },
          {
            label: 'Credit To',
            text: `${this.data.creditTo}`
          },
          {
            label: 'Address',
            text: `${this.data.address}`
          },
          {
            label: 'Account Number',
            text: `${this.data.accountNumber}`
          },
          {
            label: 'Reference',
            text: `${this.data.reference1}`
          },
          {
            label: 'Reference 2',
            text: `${this.data.reference}`
          }
        )
      } else if (this.data.provider === 'Think Green') {
        data.push(
          {
            subtitle: 'Bank Information'
          },
          {
            label: 'Name',
            text: `${this.data.bankName}`
          },
          {
            label: 'Address',
            text: `${this.data.bankAddress}`
          },
          {
            label: 'Phone',
            text: ''
          },
          {
            label: 'SWIFT Code',
            text: `${this.data.swiftCode}`
          },
          {
            subtitle: 'Beneficiary Information'
          },
          {
            label: 'Credit To',
            text: `${this.data.creditTo}`
          },
          {
            label: 'Address',
            text: `${this.data.address}`
          },
          {
            label: 'Account Number',
            text: `${this.data.accountNumber}`
          },
          {
            label: 'Reference',
            text: `${this.data.reference}`
          }
        )
      }
      return data
    },

    isFunds () {
      return this.form.type === 'Funds'
    },

    isAssets () {
      return this.form.type === 'Assets'
    },

    selectedAccount () {
      return this.accountOptions.find(item => item.id === this.form.account)
    },
  },

  watch: {
    'form.type' () {
      this.clear()
    },

    'form.currency' () {
      this.data = null
    },

    'form.account' () {
      this.clear(true)
    }
  },

  created () {
    this.$store.commit('page/setPageInfo', {
      title: 'Deposit Details',
      back: { name: 'Dashboard' }
    })
  },

  methods: {
    submit () {
      this.$v.$touch()
      if (this.$v.form.$invalid) return

      this.isFunds ? this.getFundsData() : this.getAssetsData()
    },

    async getFundsData () {
      this.loading = true

      try {
        const payload = {
          profileId: this.form.account,
          transactionType: this.form.deposit_type,
          currency: this.form.currency
        }

        const { action } = await this.$store.dispatch('transactions/action', {
          id: 'new',
          action: 'PaymentDetails',
          payload
        })

        if (action.data) {
          if (action.data.provider === 'Solid' || action.data.provider === 'Think Green' || action.data.provider === 'Argense') {
            this.data = action?.data
          } else if (this.form.currency === 'USD') {
            this.data = action.data[this.form.deposit_type === 'domestic_wire' ? 'wire-instructions' : 'wire-instructions-intl']
          } else {
            this.data = action.data['alt-currency-wire-instructions'][this.form.currency]
          }
        } else {
          this.$notify.error(window.errorMessage)
        }
      } catch (e) {
        console.error(e)
        this.$notify.error(window.errorMessage)
      } finally {
        this.loading = false
      }
    },

    async getAssetsData () {
      this.loading = true
      try {
        const payload = {
          profileId: this.form.account,
          currencyId: this.form.currency
        }

        const { action } = await this.$store.dispatch('account/action', {
          id: this.form.account,
          action: 'GetWalletPaymentDetails',
          payload
        })

        if (!action.data) this.$notify.error(window.errorMessage)

        this.data = action?.data
      } catch (e) {
        console.error(e)
        this.$notify.error(window.errorMessage)
      } finally {
        this.loading = false
      }
      this.loading = false
    },

    clear (skipAccount = false) {
      if (!skipAccount) this.form.account = null
      this.form.currency = null
      this.form.deposit_type = null
      this.data = null
      this.$v.$reset()
    }
  },

  validations () {
    const rules = {
      form: {
        account: { required }
      }
    }

    if (this.isFunds) {
    }

    return rules
  }
}
</script>
